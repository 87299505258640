(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("fs"), require("path"), require("util"), require("stream"));
	else if(typeof define === 'function' && define.amd)
		define(["fs", "path", "util", "stream"], factory);
	else if(typeof exports === 'object')
		exports["LigaturesAddon"] = factory(require("fs"), require("path"), require("util"), require("stream"));
	else
		root["LigaturesAddon"] = factory(root["fs"], root["path"], root["util"], root["stream"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__89__, __WEBPACK_EXTERNAL_MODULE__56__, __WEBPACK_EXTERNAL_MODULE__269__, __WEBPACK_EXTERNAL_MODULE__978__) {
return 